
import { ref, onMounted } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import LineChartUser from './components/LineChartUser.vue';
import BarChartUser from './components/BarChartUser.vue';
import BarChartRevenue from './components/BarChartRevenue.vue';
import ChannelDetail from './components/ChannelDetail.vue';
import dayjs from 'dayjs';
import PieChartChannels from './components/PieChartChannels.vue';
import DaysSelect from './components/daysSelect.vue';
import { getChannels, MAX_PER_PAGE, triggerExportDailyChannelCsv } from '@/services/api';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    LineChartUser,
    BarChartUser,
    BarChartRevenue,
    ChannelDetail,
    PieChartChannels,
    DaysSelect
  },
  setup() {
    const dateRangeValue = ref(null);
    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);
    const channelId = ref();
    const resourceDateRange = ref([defaultStartTime, defaultEndTime]);
    const channelOptions = ref([{
      id: 0,
      utmSource: 'all channels'
    }, {
      id: '',
      utmSource: 'organic'
    }]);

    const exportDailyChannelCsv = async () => {
      console.log('export daily channel csv');
      const exportSuccessful = await triggerExportDailyChannelCsv();

      if (exportSuccessful) {
        // Handle success (e.g., show a success message)
        console.log('export csv success', exportSuccessful)
      } else {
        // Handle failure (e.g., show an error message)
        console.log('export csv fail', exportSuccessful)
      }
    };

    onMounted(async() => {
      const { data } = await getChannels({
        query: {
          perPage: MAX_PER_PAGE
        }
      });
      channelId.value = channelOptions.value[0].id;
      data.forEach((item) => {
        channelOptions.value.push(item);
      });
    });

    // 原組件使用function
    // const setDateRange = (date: any) => {
    //   dateRange.value = [];
    //   date.forEach(element => {
    //     dateRange.value.push(element);
    //   });
    // };

    const setDateRange = () => {
      dateRange.value = resourceDateRange.value;
    };

    const ChannelTypeDaysSelect = ref([]);
    return {
      dateRangeValue,
      dateRange,
      setDateRange,
      ChannelTypeDaysSelect,
      channelId,
      channelOptions,
      exportDailyChannelCsv,
      resourceDateRange
    };
  }
};
